<template>
  <view-skeleton title="共享笔记列表" :breadcrumbs="$route.meta.breadcrumbs">
    <template #cont>
      <tableList ref="tableList" class="pa-7" />
    </template>
  </view-skeleton>
</template>

<script>
import tableList from './components/tableList'
export default {
  name: 'notesList',
  components: { tableList }
}
</script>
