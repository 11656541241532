var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.count,"items-per-page":_vm.queryParams.pageSize,"page":_vm.queryParams.page,"footer-props":{
    itemsPerPageOptions: [5,10,30,50]
  }},on:{"update:options":_vm.pagination},scopedSlots:_vm._u([{key:"item.noteStatus",fn:function(ref){
  var item = ref.item;
return [_vm._v(_vm._s(_vm._f("noteStatusFilter")(item.noteStatus)))]}},{key:"item.profilePhotoUrl",fn:function(ref){
  var item = ref.item;
return [_c('v-img',{attrs:{"src":item.profilePhotoUrl,"width":"30","height":"30"}})]}},{key:"item.operate",fn:function(ref){
  var item = ref.item;
return [(+item.noteStatus === 1)?_c('a',{staticClass:"operate-btn",on:{"click":function($event){return _vm.approved(item)}}},[_vm._v("审核通过")]):_vm._e(),(+item.noteStatus === 1)?_c('a',{staticClass:"operate-btn",on:{"click":function($event){return _vm.rejected(item)}}},[_vm._v("审核驳回")]):_vm._e(),(+item.noteStatus === 2)?_c('a',{staticClass:"operate-btn",on:{"click":function($event){return _vm.rejected(item)}}},[_vm._v("再次驳回")]):_vm._e(),_c('a',{staticClass:"operate-btn",on:{"click":function($event){return _vm.check(item)}}},[_vm._v("查看")])]}}])}),_c('Popup',{attrs:{"title":"文章详情","sureBtn":false},model:{value:(_vm.popupShow),callback:function ($$v) {_vm.popupShow=$$v},expression:"popupShow"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.noteDetail)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }