<template>
  <div>
    <v-data-table
      disable-sort
      :headers="headers"
      :items="items"
      :loading="loading"
      :server-items-length="count"
      :items-per-page="queryParams.pageSize"
      :page="queryParams.page"
      @update:options="pagination"
      :footer-props="{
      itemsPerPageOptions: [5,10,30,50]
    }"
    >
      <template #item.noteStatus="{item}">{{item.noteStatus | noteStatusFilter}}</template>
      <template #item.profilePhotoUrl="{item}">
        <v-img :src="item.profilePhotoUrl" width="30" height="30" />
      </template>
      <template #item.operate="{item}">
        <a class="operate-btn" @click="approved(item)" v-if="+item.noteStatus === 1">审核通过</a>
        <a class="operate-btn" @click="rejected(item)" v-if="+item.noteStatus === 1">审核驳回</a>
        <a class="operate-btn" @click="rejected(item)" v-if="+item.noteStatus === 2">再次驳回</a>
        <a class="operate-btn" @click="check(item)">查看</a>
      </template>
    </v-data-table>
    <Popup v-model="popupShow" title="文章详情" :sureBtn="false">
      <div v-html="noteDetail"></div>
    </Popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      queryParams: {
        page: 1,
        pageSize: 10
      },
      count: 0,
      loading: false,
      popupShow: false,
      noteDetail: ''
    }
  },
  computed: {
    headers() {
      return [
        { text: '创建时间', value: 'createTime' },
        { text: '文章标题', value: 'noteTitle' },
        { text: '文章标签', value: 'noteTag' },
        { text: '昵称', value: 'nickname' },
        { text: '手机号', value: 'phone' },
        { text: '用户头像', value: 'profilePhotoUrl' },
        { text: '审核状态', value: 'noteStatus' },
        { text: '操作', value: 'operate', width: 100 }
      ]
    }
  },
  filters: {
    noteStatusFilter(v) {
      const options = ['审核中', '审核通过', '审核拒绝']
      return options[v - 1]
    }
  },
  methods: {
    pagination(e) {
      const { itemsPerPage: pageSize, page } = e
      Object.assign(this.queryParams, {
        pageSize,
        page
      })
      this.loadApp()
    },
    async loadApp() {
      this.loading = true
      const res = await this.$post('/adminNote/queryAllByLimit', this.queryParams, false)
      this.loading = false
      if (res.code !== 200) return
      console.log(res)
      this.count = res.count
      this.items = res.data.data.map((v) => {
        return { ...v.noteInfo, ...v.memberInfo }
      })
      console.log(this.items)
    },
    async approved(item) {
      this.$cux.asyncOperation({
        icon: 'warning',
        text: '确定通过审核吗?',
        preConfirm: async () => {
          const res = await this.$post('/adminNote/examine', { id: item.id })
          const isSuccess = res.code === 200
          this.$cux
            .alert({
              text: isSuccess ? '审核成功' : '审核失败',
              icon: isSuccess ? 'success' : 'error',
              timer: 1000
            })
            .then(() => {
              if (isSuccess) {
                this.loadApp()
              }
            })
        }
      })
    },
    rejected(item) {
      this.$cux.asyncOperation({
        icon: 'warning',
        text: '确定驳回审核吗?',
        preConfirm: async () => {
          const res = await this.$post('/adminNote/reject', { id: item.id })
          const isSuccess = res.code === 200
          this.$cux
            .alert({
              text: isSuccess ? '驳回成功' : '驳回失败',
              icon: isSuccess ? 'success' : 'error',
              timer: 1000
            })
            .then(() => {
              if (isSuccess) {
                this.loadApp()
              }
            })
        }
      })
    },
    check(item) {
      this.noteDetail = item.noteDetail
      this.popupShow = true
    }
  }
}
</script>
